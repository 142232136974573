const andromeda = {
  payload: {},
  ShakeHand () {
    if (window.location.href.indexOf('localhost') > 0) {
      return
    }

    let url

    if (window.location.host.includes('localhost')) {
      url = 'http://0.0.0.0:8000/'
    } else {
      url = 'https://truck.marahin.pl/'
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ fpData: JSON.stringify(data()), payload: andromeda.payload })
    })
  }
}

const data = function () {
  return {
    screenSize: getScreenSize(),
    availableScreenSize: getAvailScreenSize(),
    cookiesEnabled: getCookiesEnabled(),
    mathTan: getMathTan(),
    dateFormat: getDateFormat(),
    touchCompatibility: getTouchCompatibility(),
    languages: getLanguages(),
    hasLocalStorage: hasLocalStorage(),
    hasSessionStorage: hasSessionStorage(),
    hasUserData: hasUserData(),
    hasIndexedDB: hasIndexedDB(),
    hasDoNotTrack: hasDoNotTrack(),
    hardwareConcurrency: getHardwareConcurrency(),
    cpuClass: getCpuClass(),
    platform: getPlatform(),
    plugins: getPlugins(),
    IEPlugins: getIEPlugins(),
    installedFonts: getInstalledFontsJs(),
    webGLVendor: getWebGLVendor(),
    webGLRenderer: getWebGLRenderer(),
    canvasFp: getCanvasFp()
  }
}

window.andromeda = andromeda

const getScreenSize = function () {
  let screenSize = []

  if (window.screen.width > window.screen.height) {
    screenSize = [window.screen.width, window.screen.height]
  } else {
    screenSize = [window.screen.height, window.screen.width]
  }
  return screenSize
}

const getAvailScreenSize = function () {
  let availScreenSize = []
  if (window.screen.availWidth > window.screen.availHeight) {
    availScreenSize = [window.screen.availWidth, window.screen.availHeight]
  } else {
    availScreenSize = [window.screen.availHeight, window.screen.availWidth]
  }
  return availScreenSize
}

const getCookiesEnabled = function () {
  let enabled = window.navigator.cookieEnabled
  if (!enabled) {
    document.cookie = 'fpCookie'
    enabled = document.cookie.includes('fpCookie')
  }
  return enabled
}

const getMathTan = function () {
  return Math.tan(-1e300)
}

const getDateFormat = function () {
  const date = new Date()
  date.setTime(0)
  return date.toLocaleString()
}

const getTouchCompatibility = function () {
  let maxTouchPoints = 0
  let touchEvent = false
  if (typeof window.navigator.maxTouchPoints !== 'undefined') {
    maxTouchPoints = window.navigator.maxTouchPoints
  } else if (typeof window.navigator.msMaxTouchPoints !== 'undefined') {
    maxTouchPoints = window.navigator.msMaxTouchPoints
  }
  try {
    document.createEvent('TouchEvent')
    touchEvent = true
  } catch (e) {}
  const touchStart = 'ontouchstart' in window
  return [maxTouchPoints, touchEvent, touchStart]
}

const getLanguages = function () {
  const languages = []
  languages.push(window.navigator.language)
  languages.push(window.navigator.languages)
  languages.push(window.navigator.userLanguage)
  languages.push(window.navigator.browserLanguage)
  languages.push(window.navigator.systemLanguage)
  return languages
}

const hasLocalStorage = function () {
  const item = 'awesome_item'
  try {
    localStorage.setItem(item, item)
    localStorage.removeItem(item)
    return true
  } catch (e) {
    return false
  }
}

const hasSessionStorage = function () {
  const item = 'nice_item'
  try {
    sessionStorage.setItem(item, item)
    sessionStorage.removeItem(item)
    return true
  } catch (e) {
    return false
  }
}

const hasUserData = function () {
  return !!document.createElement('div').addBehavior
}

const hasIndexedDB = function () {
  try {
    return !!window.indexedDB
  } catch (e) {
    return true
  }
}

const hasDoNotTrack = function () {
  return window.navigator.doNotTrack || window.navigator.msDoNotTrack || window.doNotTrack || undefined
}

const getHardwareConcurrency = function () {
  return window.navigator.hardwareConcurrency
}

const getCpuClass = function () {
  return window.navigator.cpuClass
}

const getPlatform = function () {
  return window.navigator.platform
}

const getPlugins = function () {
  const plugins = []
  if (window.navigator.plugins) {
    for (let i = 0; i < window.navigator.plugins.length; i++) {
      const plugin = window.navigator.plugins[i]
      const mt = plugin[0]
      if (plugin) {
        plugins.push(['>Plugin ' + i + ': ' + plugin.name, plugin.filename, plugin.description, mt.type, mt.suffixes].join(', '))
      }
    }
  }
  return plugins.length === 0 ? 'empty' : plugins.join(';;')
}

const getIEPlugins = function () {
  const result = []
  if ((Object.getOwnPropertyDescriptor && Object.getOwnPropertyDescriptor(window, 'ActiveXObject')) || ('ActiveXObject' in window)) {
    const names = [
      'AcroPDF.PDF', // Adobe PDF reader 7+
      'Adodb.Stream',
      'AgControl.AgControl', // Silverlight
      'DevalVRXCtrl.DevalVRXCtrl.1',
      'MacromediaFlashPaper.MacromediaFlashPaper',
      'Msxml2.DOMDocument',
      'Msxml2.XMLHTTP',
      'PDF.PdfCtrl', // Adobe PDF reader 6-
      'QuickTime.QuickTime', // QuickTime
      'QuickTimeCheckObject.QuickTimeCheck.1',
      'RealPlayer',
      'RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)',
      'RealVideo.RealVideo(tm) ActiveX Control (32-bit)',
      'Scripting.Dictionary',
      'SWCtl.SWCtl', // ShockWave player
      'Shell.UIHelper',
      'ShockwaveFlash.ShockwaveFlash', // flash plugin
      'Skype.Detection',
      'TDCCtl.TDCCtl',
      'WMPlayer.OCX', // Windows media player
      'rmocx.RealPlayer G2 Control',
      'rmocx.RealPlayer G2 Control.1'
    ]
    // starting to detect plugins in IE
    for (let i = 0; i < names.length; i++) {
      try {
        // eslint-disable-next-line no-undef
        a = new window.ActiveXObject(names[i])
        result.push(names[i])
      } catch (e) {
      }
    }
  }
  return result.length === 0 ? 'empty' : result
}

const getWebGLVendor = function () {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    return ctx.getParameter(ctx.getExtension('WEBGL_debug_renderer_info').UNMASKED_VENDOR_WEBGL)
  } catch (e) {
    return 'not supported'
  }
}

const getWebGLRenderer = function () {
  try {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    return ctx.getParameter(ctx.getExtension('WEBGL_debug_renderer_info').UNMASKED_RENDERER_WEBGL)
  } catch (e) {
    return 'not supported'
  }
}

const getInstalledFontsJs = function () {
  const fontList = [
    'Andale Mono', 'Arial', 'Arial Black', 'Arial Hebrew', 'Arial MT', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Bitstream Vera Sans Mono', 'Book Antiqua', 'Bookman Old Style', 'Calibri', 'Cambria', 'Cambria Math', 'Century', 'Century Gothic', 'Century Schoolbook', 'Comic Sans', 'Comic Sans MS', 'Consolas', 'Courier', 'Courier New', 'Garamond', 'Geneva', 'Georgia', 'Helvetica', 'Helvetica Neue', 'Impact', 'Lucida Bright', 'Lucida Calligraphy', 'Lucida Console', 'Lucida Fax', 'LUCIDA GRANDE', 'Lucida Handwriting', 'Lucida Sans', 'Lucida Sans Typewriter', 'Lucida Sans Unicode', 'Microsoft Sans Serif', 'Monaco', 'Monotype Corsiva', 'MS Gothic', 'MS Outlook', 'MS PGothic', 'MS Reference Sans Serif', 'MS Sans Serif', 'MS Serif', 'MYRIAD', 'MYRIAD PRO', 'Palatino', 'Palatino Linotype', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Light', 'Segoe UI Semibold', 'Segoe UI Symbol', 'Tahoma', 'Times', 'Times New Roman', 'Times New Roman PS', 'Trebuchet MS', 'Ubuntu', 'Verdana', 'Wingdings', 'Wingdings 2', 'Wingdings 3'
  ]

  const baseFonts = ['monospace', 'sans-serif', 'serif']
  const testString = 'mmmmmmmmmmlli'
  const testSize = '72px'

  const h = document.getElementsByTagName('body')[0]

  const s = document.createElement('span')
  s.style.position = 'absolute'
  s.style.left = '-9999px'
  s.style.lineHeight = 'normal'

  // css reset
  s.style.fontStyle = 'normal'
  s.style.fontWeight = 'normal'
  s.style.letterSpacing = 'normal'
  s.style.lineBreak = 'auto'
  s.style.lineHeight = 'normal'
  s.style.texTransform = 'none'
  s.style.textAlign = 'left'
  s.style.textDecoration = 'none'
  s.style.textShadow = 'none'
  s.style.whiteSpace = 'normal'
  s.style.wordBreak = 'normal'
  s.style.wordSpacing = 'normal'

  s.style.fontSize = testSize
  s.innerHTML = testString
  const defaultWidth = {}
  const defaultHeight = {}
  for (let index = 0, length = baseFonts.length; index < length; index++) {
    // get the default width for the three base fonts
    s.style.fontFamily = baseFonts[index]
    h.appendChild(s)
    defaultWidth[baseFonts[index]] = s.offsetWidth // width for the default font

    defaultHeight[baseFonts[index]] = s.offsetHeight // height for the defualt font
    h.removeChild(s)
  }
  const detect = function (font) {
    let detected = false
    for (let index = 0, l = baseFonts.length; index < l; index++) {
      s.style.fontFamily = font + ',' + baseFonts[index] // name of the font along with the base font for fallback.
      h.appendChild(s)
      const matched = (s.offsetWidth !== defaultWidth[baseFonts[index]] || s.offsetHeight !== defaultHeight[baseFonts[index]])
      h.removeChild(s)
      detected = detected || matched
    }
    return detected
  }

  let jsInstalledFonts = ''
  for (let i = 0, l = fontList.length; i < l; i++) {
    if (detect(fontList[i])) {
      jsInstalledFonts += fontList[i]
      jsInstalledFonts += ';'
    }
  }
  return jsInstalledFonts
}

const canvas = document.createElement('canvas')
const getCanvasFp = function () {
  const result = []
  // Very simple now, need to make it more complex (geo shapes etc)
  canvas.width = 2000
  canvas.height = 200
  canvas.style.display = 'inline'
  const ctx = canvas.getContext('2d')
  // detect browser support of canvas winding
  // http://blogs.adobe.com/webplatform/2013/01/30/winding-rules-in-canvas/
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/canvas/winding.js
  ctx.rect(0, 0, 10, 10)
  ctx.rect(2, 2, 6, 6)
  result.push('canvas winding:' + ((ctx.isPointInPath(5, 5, 'evenodd') === false) ? 'yes' : 'no'))

  ctx.textBaseline = 'alphabetic'
  ctx.fillStyle = '#f60'
  ctx.fillRect(125, 1, 62, 20)
  ctx.fillStyle = '#069'
  ctx.font = '13pt no-real-font-123'
  ctx.fillText('Sphinx of black quartz, judge my vow \uD83D\uDC3C\uD83D\uDE04', 2, 20)
  ctx.fillStyle = 'rgba(102, 204, 0, 0.23456789)'
  ctx.font = '18pt Arial'
  ctx.fillText('Sphinx of black quartz, judge my vow \uD83D\uDC3C\uD83D\uDE04', 4, 22)

  // canvas blending
  // http://blogs.adobe.com/webplatform/2013/01/28/blending-features-in-canvas/
  // http://jsfiddle.net/NDYV8/16/
  ctx.globalCompositeOperation = 'multiply'
  ctx.fillStyle = 'rgb(255,0,255)'
  ctx.beginPath()
  ctx.arc(50.123456789, 50, 50, 0, Math.PI * 2, true)
  ctx.closePath()
  ctx.fill()
  ctx.fillStyle = 'rgb(0,255,255)'
  ctx.beginPath()
  ctx.arc(100, 50.456, 50, 0, Math.PI * 2, true)
  ctx.closePath()
  ctx.fill()
  ctx.fillStyle = 'rgb(255,125,0)'
  ctx.beginPath()
  ctx.arc(75, 100, 50.789, 0, Math.PI * 2, true)
  ctx.closePath()
  ctx.fill()
  ctx.fillStyle = 'rgb(125,0,255)'
  // canvas winding
  // http://blogs.adobe.com/webplatform/2013/01/30/winding-rules-in-canvas/
  // http://jsfiddle.net/NDYV8/19/Â§
  ctx.arc(75, 75, 75, 0, Math.PI * 2, true)
  ctx.shadowBlur = 10
  ctx.shadowColor = 'blue'
  ctx.arc(75, 75, 25, 0, Math.PI * 2, true)
  ctx.fill('evenodd')

  if (canvas.toDataURL) { result.push('canvas fp:' + canvas.toDataURL()) }
  window.canvasThing = canvas
  return result.join('~')
}

// eslint-disable-next-line no-empty-pattern
export default ({ }, inject) => {
  inject('andromeda', (payload) => { andromeda.payload = payload; andromeda.ShakeHand() })
}
