

import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const links = ref([
      {
        name: 'Highscores',
        path: '/highscores',
        icon: 'mdi-podium'
      },
      {
        name: 'Calculators',
        path: '/',
        icon: 'mdi-calculator'
      },
      {
        name: 'Transfers',
        path: '/transfers/all/all',
        icon: 'mdi-swap-horizontal'
      },
      {
        name: 'Deaths',
        path: '/deaths/all',
        icon: 'mdi-skull'
      }
    ])

    const drawer = ref(false)
    const group = ref(1)

    return { links, drawer, group }
  }
})

