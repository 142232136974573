import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4bae6a20 = () => interopDefault(import('../pages/highscores/index.vue' /* webpackChunkName: "pages/highscores/index" */))
const _290e249c = () => interopDefault(import('../pages/highscores/guilds.vue' /* webpackChunkName: "pages/highscores/guilds" */))
const _0442ea50 = () => interopDefault(import('../pages/highscores/players.vue' /* webpackChunkName: "pages/highscores/players" */))
const _5d5ff973 = () => interopDefault(import('../pages/highscores/worlds.vue' /* webpackChunkName: "pages/highscores/worlds" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ed8c0222 = () => interopDefault(import('../pages/characters/_name.vue' /* webpackChunkName: "pages/characters/_name" */))
const _2dd57529 = () => interopDefault(import('../pages/deaths/_world.vue' /* webpackChunkName: "pages/deaths/_world" */))
const _5cfe74e5 = () => interopDefault(import('../pages/guilds/_name.vue' /* webpackChunkName: "pages/guilds/_name" */))
const _05363c86 = () => interopDefault(import('../pages/transfers/_fromWorld/_toWorld.vue' /* webpackChunkName: "pages/transfers/_fromWorld/_toWorld" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/highscores",
    component: _4bae6a20,
    name: "highscores"
  }, {
    path: "/highscores/guilds",
    component: _290e249c,
    name: "highscores-guilds"
  }, {
    path: "/highscores/players",
    component: _0442ea50,
    name: "highscores-players"
  }, {
    path: "/highscores/worlds",
    component: _5d5ff973,
    name: "highscores-worlds"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/characters/:name?",
    component: _ed8c0222,
    name: "characters-name"
  }, {
    path: "/deaths/:world?",
    component: _2dd57529,
    name: "deaths-world"
  }, {
    path: "/guilds/:name?",
    component: _5cfe74e5,
    name: "guilds-name"
  }, {
    path: "/transfers/:fromWorld?/:toWorld?",
    component: _05363c86,
    name: "transfers-fromWorld-toWorld"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
