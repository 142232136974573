
export default {
  props: {
    isPlaceholder: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    }
  },

  data () {
    return {
      deaths: [],
      loading: true
    }
  },

  async fetch () {
    if (!this.isPlaceholder) {
      await this.loadLatestDeaths()
    }
  },

  methods: {
    loadLatestDeaths () {
      const { $axios, $config, $moment } = this.$nuxt.context

      const url = `${$config.apiUrl}/api/v2/latest_deaths`

      const reg = /.+Level (\d+).+/

      return $axios.$get(url).then((resp) => {
        this.deaths = resp.map((el) => {
          const obj = { ...el }

          const match = reg.exec(el.death_message)
          if (match !== null || match !== undefined || match.length < 2) {
            obj.death_level = obj.level
          } else {
            obj.death_level = match[1]
          }

          let deathStage = 1

          const momentDiedAt = $moment(el.died_at)

          if ($moment().utc().subtract(30, 'minutes').isBefore(momentDiedAt)) {
            deathStage = 1
          } else if (
            $moment().utc().subtract(60, 'minutes').isBefore(momentDiedAt)
          ) {
            deathStage = 2
          } else {
            deathStage = 3
          }
          obj.deathStage = deathStage

          return obj
        })

        this.loading = false
      })
    }
  }
}
