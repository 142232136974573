export const state = () => ({
  list: []
})

export const mutations = {
  load () {
    state.list = JSON.parse(localStorage.getItem('tph-history') || '[]')
  },

  add (state, item) {
    state.list.push(item)

    localStorage.setItem('tph-history', JSON.stringify(state.list))
  },

  remove (state, { item }) {
    state.list.splice(state.list.indexOf(item), 1)

    localStorage.setItem('tph-history', JSON.stringify(state.list))
  }
}
