export const Bosstiary = () => import('../../components/Bosstiary.vue' /* webpackChunkName: "components/bosstiary" */).then(c => wrapFunctional(c.default || c))
export const Calculator = () => import('../../components/Calculator.vue' /* webpackChunkName: "components/calculator" */).then(c => wrapFunctional(c.default || c))
export const CharacterLink = () => import('../../components/CharacterLink.vue' /* webpackChunkName: "components/character-link" */).then(c => wrapFunctional(c.default || c))
export const DailyProfitChart = () => import('../../components/DailyProfitChart.vue' /* webpackChunkName: "components/daily-profit-chart" */).then(c => wrapFunctional(c.default || c))
export const EntitySearch = () => import('../../components/EntitySearch.vue' /* webpackChunkName: "components/entity-search" */).then(c => wrapFunctional(c.default || c))
export const ExperienceCalendar = () => import('../../components/ExperienceCalendar.vue' /* webpackChunkName: "components/experience-calendar" */).then(c => wrapFunctional(c.default || c))
export const ExperienceValue = () => import('../../components/ExperienceValue.vue' /* webpackChunkName: "components/experience-value" */).then(c => wrapFunctional(c.default || c))
export const GuildChart = () => import('../../components/GuildChart.vue' /* webpackChunkName: "components/guild-chart" */).then(c => wrapFunctional(c.default || c))
export const GuildLink = () => import('../../components/GuildLink.vue' /* webpackChunkName: "components/guild-link" */).then(c => wrapFunctional(c.default || c))
export const LatestDeaths = () => import('../../components/LatestDeaths.vue' /* webpackChunkName: "components/latest-deaths" */).then(c => wrapFunctional(c.default || c))
export const PlayerSessions = () => import('../../components/PlayerSessions.vue' /* webpackChunkName: "components/player-sessions" */).then(c => wrapFunctional(c.default || c))
export const ProfitValue = () => import('../../components/ProfitValue.vue' /* webpackChunkName: "components/profit-value" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
