import { watch, Ref, ref } from '@nuxtjs/composition-api'

import _ from 'lodash'

interface Entity {
  type: string;
  name: string;
  world: string;
}

interface useEntitiesFunc {
  selectedEntity: Ref<Entity | null>;
  loading: Ref<boolean>;
  entities: Ref<Array<Entity>>;
  searchQuery: Ref<string | null>;
}

export function useEntities ($axios: any, $config: any): useEntitiesFunc {
  const loading: Ref<boolean> = ref(false)
  const searchQuery: Ref<string | null> = ref(null)
  const entitySearchQuery: Ref<string |null> = ref(null)
  const entities: Ref<Array<Entity>> = ref([])

  const searchForEntities = _.debounce(function () {
    loading.value = true

    const getCharacters = $axios.get(`${$config.apiUrl}/api/v2/characters?q=${entitySearchQuery.value}`)
    const getGuilds = $axios.get(`${$config.apiUrl}/api/v2/guilds?q=${entitySearchQuery.value}`)

    Promise.all([getCharacters, getGuilds]).then((responses) => {
      entities.value = [
        ...responses[0].data.map((el: Entity) => ({ ...el, type: 'characters' })),
        ...responses[1].data.map((el:Entity) => ({ ...el, type: 'guilds' }))
      ]

      entities.value = entities.value.sort((a, b) => a.name.length > b.name.length ? 1 : -1)
    }).catch((err) => {
      console.log(err) // XD
      entities.value = []
    }).finally(() => {
      loading.value = false
    })
  }, 250)

  watch(searchQuery, (val) => {
    entitySearchQuery.value = val
    searchForEntities()
  })

  const selectedEntity: Ref<Entity | null> = ref(null)

  return {
    selectedEntity, loading, entities, searchQuery
  }
}
