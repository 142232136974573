
import {
  watch,
  useRouter,
  useContext,
  defineComponent
} from '@nuxtjs/composition-api'

import { useEntities } from '~/api/entity'

export default defineComponent({
  setup () {
    const { $axios, $config } = useContext()
    const { selectedEntity, loading, entities, searchQuery } = useEntities(
      $axios,
      $config
    )

    const router = useRouter()

    watch(selectedEntity, (val) => {
      console.log(val)

      if (val != null) {
        router.push({ path: `/${val.type}/${val.name}` })
      }
    })

    return {
      selectedEntity,
      loading,
      entities,
      searchQuery
    }
  }
})
